import React from 'react'
import MembersList from '../Wellness/MembersList'

function Wellness() {
  return (
    <div>
      <MembersList />
    </div>
  )
}

export default Wellness
