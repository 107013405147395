import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { fetchPatientRecords } from '../../actions/patientActions'
import PatientTable from './PatientTable' // Import PatientTable component

const PatientList = () => {
  const [selectedLocation, setSelectedLocation] = useState('')
  const dispatch = useDispatch()
  const { loading, patientsRecords, metadata, error } = useSelector(
    (state) => state.patient
  )

  useEffect(() => {
    dispatch(
      fetchPatientRecords({
        page: 1,
        search_term: '',
        location: selectedLocation,
        startDate: '',
        endDate: '',
      })
    )
  }, [dispatch, selectedLocation])

  const handlePageChange = (page) => {
    dispatch(
      fetchPatientRecords({
        page,
        search_term: '',
        location: selectedLocation,
        startDate: '',
        endDate: '',
      })
    )
  }

  const handleLocationChange = (e) => {
    setSelectedLocation(e.target.value)
    dispatch(
      fetchPatientRecords({
        page: 1,
        search_term: '',
        location: e.target.value,
        startDate: '',
        endDate: '',
      })
    )
  }

  if (loading) return <p>Loading...</p>
  if (error) return <p>Error: {error}</p>

  return (
    <div>
      {/* Conditionally render the total count based on selectedLocation */}
      {selectedLocation && (
        <p className="mb-4">Total Patients: {metadata.total}</p>
      )}
      <div className="mb-4">
        <label htmlFor="location" className="mr-2">
          Filter by Location:
        </label>
        <select
          id="location"
          value={selectedLocation}
          onChange={handleLocationChange}
          className="rounded border border-gray-300 p-2"
        >
          <option value="">All Locations</option>
          {/* <option value="Nyahururu">Nyahururu</option>
          <option value="Kakamega">Kakamega</option>
          <option value="Narok">Narok</option>
          <option value="Kisumu">Kisumu</option>
          <option value="Meru">Meru</option>
          <option value="Mombasa">Mombasa</option>
          <option value="Garissa">Garissa</option>
          <option value="Kirinyaga">Kirinyaga</option>
          <option value="Siaya">Siaya</option>
          <option value="Nairobi">Nairobi</option>
          <option value="Machakos">Machakos</option>
          <option value="Bungoma">Bungoma</option> */}
          <option value="Bomet">Bomet</option>
        </select>
      </div>
      <PatientTable records={patientsRecords} /> {/* Render table */}
      <div className="mt-4 flex items-center justify-between">
        <button
          onClick={() => handlePageChange(metadata.current_page - 1)}
          disabled={metadata.current_page === 1}
          className="flex items-center rounded bg-brandPrimary px-4 py-2 text-white disabled:bg-gray-300"
        >
          <i className="fa fa-chevron-left"></i>
          <span className="ml-2">Previous</span>
        </button>
        <p>
          Page {metadata.current_page} of {metadata.last_page}
        </p>
        <button
          onClick={() => handlePageChange(metadata.current_page + 1)}
          disabled={metadata.current_page === metadata.last_page}
          className="flex items-center rounded bg-brandSecondary px-4 py-2 text-white disabled:bg-gray-300"
        >
          <span className="mr-2">Next</span>
          <i className="fa fa-chevron-right"></i>
        </button>
      </div>
    </div>
  )
}

export default PatientList
