import { API_URL_MAIN, API_KEY } from '../config/config'
import axios from 'axios'
import {
  REGISTER_REQUEST,
  REGISTER_SUCCESS,
  REGISTER_FAILURE,
  LOGIN_REQUEST,
  LOGIN_SUCCESS,
  LOGIN_FAILURE,
  LOGOUT,
  GET_ALL_USERS_REQUEST,
  GET_ALL_USERS_SUCCESS,
  GET_ALL_USERS_FAILURE,
  REFRESH_TOKEN_REQUEST,
  REFRESH_TOKEN_SUCCESS,
  REFRESH_TOKEN_FAILURE,
  EDIT_USER_REQUEST,
  EDIT_USER_SUCCESS,
  EDIT_USER_FAILURE,
  DELETE_USER_REQUEST,
  DELETE_USER_SUCCESS,
  DELETE_USER_FAILURE,
  DELETE_ALL_USERS_REQUEST,
  DELETE_ALL_USERS_SUCCESS,
  DELETE_ALL_USERS_FAILURE,
} from '../constants/userConstants'
import { toast } from 'react-toastify'

const axiosConfig = {
  headers: {
    'X-API-KEY': API_KEY,
  },
  withCredentials: true,
}

// Register User
export const register =
  (firstName, lastName, email, password) => async (dispatch) => {
    dispatch({ type: REGISTER_REQUEST })
    try {
      const res = await axios.post(
        `${API_URL_MAIN}/auth/register`,
        { firstName, lastName, email, password },
        axiosConfig
      )
      dispatch({
        type: REGISTER_SUCCESS,
        payload: res.data,
      })
      toast.success('Registration successful')
    } catch (error) {
      dispatch({
        type: REGISTER_FAILURE,
        payload: error.response?.data.message || error.message,
      })
    }
  }

// Login User
export const login = (email, password) => async (dispatch) => {
  dispatch({ type: LOGIN_REQUEST })
  try {
    const { data } = await axios.post(
      `${API_URL_MAIN}/auth/login`,
      { email, password },
      axiosConfig
    )
    dispatch({ type: LOGIN_SUCCESS, payload: data })
    toast.success('Login successful')
  } catch (error) {
    dispatch({
      type: LOGIN_FAILURE,
      payload: error.response?.data.message || error.message,
    })
    toast.error('Login failed. Please try again.')
  }
}

// Refresh Token
export const refreshToken = () => async (dispatch) => {
  dispatch({ type: REFRESH_TOKEN_REQUEST })
  try {
    const res = await axios.post(
      `${API_URL_MAIN}/auth/refresh-token`,
      null,
      axiosConfig
    )
    dispatch({
      type: REFRESH_TOKEN_SUCCESS,
      payload: res.data,
    })
  } catch (error) {
    dispatch({
      type: REFRESH_TOKEN_FAILURE,
      payload: error.response.data,
    })
  }
}

// Edit User
export const editUser = (userId, userData) => async (dispatch) => {
  dispatch({ type: EDIT_USER_REQUEST })
  try {
    const res = await axios.put(
      `${API_URL_MAIN}/auth/users/${userId}`,
      userData,
      axiosConfig
    )
    dispatch({
      type: EDIT_USER_SUCCESS,
      payload: res.data,
    })
  } catch (error) {
    dispatch({
      type: EDIT_USER_FAILURE,
      payload: error.response.data,
    })
  }
}

// Delete User
export const deleteUser = (userId) => async (dispatch) => {
  dispatch({ type: DELETE_USER_REQUEST })
  try {
    await axios.delete(`${API_URL_MAIN}/auth/users/${userId}`, axiosConfig)
    dispatch({
      type: DELETE_USER_SUCCESS,
      payload: userId,
    })
  } catch (error) {
    dispatch({
      type: DELETE_USER_FAILURE,
      payload: error.response.data,
    })
  }
}

// Delete All Users
export const deleteAllUsers = () => async (dispatch) => {
  dispatch({ type: DELETE_ALL_USERS_REQUEST })
  try {
    await axios.delete(`${API_URL_MAIN}/auth/users`, axiosConfig)
    dispatch({
      type: DELETE_ALL_USERS_SUCCESS,
    })
  } catch (error) {
    dispatch({
      type: DELETE_ALL_USERS_FAILURE,
      payload: error.response.data,
    })
  }
}

// Get All Users
export const getAllUsers =
  ({ page = 1, size = 10 } = {}) =>
  async (dispatch) => {
    dispatch({ type: GET_ALL_USERS_REQUEST })
    try {
      const { data } = await axios.get(`${API_URL_MAIN}/auth/users`, {
        params: { page, size },
        ...axiosConfig,
      })
      dispatch({
        type: GET_ALL_USERS_SUCCESS,
        payload: data,
      })
    } catch (error) {
      dispatch({
        type: GET_ALL_USERS_FAILURE,
        payload: error.response.data,
      })
    }
  }

// Logout User
export const logout = () => (dispatch) => {
  document.cookie.split(';').forEach((c) => {
    document.cookie = c
      .replace(/^ +/, '')
      .replace(/=.*/, `=;expires=${new Date().toUTCString()};path=/`)
  })
  localStorage.clear()
  dispatch({ type: LOGOUT })
}
