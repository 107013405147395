import {
  FETCH_PATIENT_ALL_FAILURE,
  FETCH_PATIENT_ALL_REQUEST,
  FETCH_PATIENT_ALL_SUCCESS,
} from '../constants/patientConstants'

const initialState = {
  loading: false,
  patientsRecords: [],
  metadata: {},
  error: null,
}

const chartReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_PATIENT_ALL_REQUEST:
      return { ...state, loading: true, error: null }
    case FETCH_PATIENT_ALL_SUCCESS:
      return {
        ...state,
        loading: false,
        patientsRecords: action.payload.patientsRecords,
        metadata: action.payload.metadata,
      }
    case FETCH_PATIENT_ALL_FAILURE:
      return { ...state, loading: false, error: action.payload }
    default:
      return state
  }
}

export default chartReducer
