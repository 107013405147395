import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { fetchallPatientRecords } from '../../actions/patientActions'
import PatientChart from './PatientChart' // Import PatientChart component

// Helper function to format date
const formatDate = (dateString) => {
  const date = new Date(dateString)
  const year = date.getFullYear().toString().slice(-2) // Get last two digits of the year
  const month = (date.getMonth() + 1).toString().padStart(2, '0') // Months are 0-based, so add 1
  const day = date.getDate().toString().padStart(2, '0') // Pad single digits with a leading zero
  return `${year}-${month}-${day}`
}

// Process chart data function
const processChartData = (records) => {
  if (!Array.isArray(records)) {
    // If records is not an array, return empty chart data
    return {
      labels: Array.from({ length: 24 }, (_, i) => `${i}:00`),
      datasets: [
        {
          label: 'Patients Added Per Hour',
          data: Array(24).fill(0),
          borderColor: 'rgba(75, 192, 192, 1)',
          backgroundColor: 'rgba(75, 192, 192, 0.2)',
          fill: true,
        },
      ],
    }
  }

  const hourlyCounts = {}
  records.forEach((record) => {
    const hour = new Date(record.createdAt).getHours() // Get the hour from the timestamp
    if (!hourlyCounts[hour]) {
      hourlyCounts[hour] = 0
    }
    hourlyCounts[hour]++
  })

  const labels = Array.from({ length: 24 }, (_, i) => `${i}:00`)
  const data = labels.map((label) => hourlyCounts[label.split(':')[0]] || 0)

  return {
    labels,
    datasets: [
      {
        label: 'Patients Added Per Hour',
        data,
        borderColor: 'rgba(75, 192, 192, 1)',
        backgroundColor: 'rgba(75, 192, 192, 0.2)',
        fill: true,
      },
    ],
  }
}

const PatientChartAnalysis = () => {
  const [selectedLocation, setSelectedLocation] = useState('')
  const dispatch = useDispatch()
  const { loading, patientsRecords, error } = useSelector(
    (state) => state.chart
  )

  useEffect(() => {
    console.log('Fetching records for location:', selectedLocation) // Debugging line
    dispatch(
      fetchallPatientRecords({
        location: selectedLocation,
        startDate: '',
        endDate: '',
      })
    )
  }, [dispatch, selectedLocation])

  const handleLocationChange = (e) => {
    const location = e.target.value
    setSelectedLocation(location)
    console.log('Location changed to:', location) // Debugging line
    dispatch(fetchallPatientRecords({ location, startDate: '', endDate: '' }))
  }

  const chartData = processChartData(patientsRecords)
  console.log('Chart Data:', chartData) // Debugging line

  if (loading) return <p>Loading...</p>
  if (error) return <p>Error: {error}</p>

  return (
    <div>
      <PatientChart data={chartData} />
    </div>
  )
}

export default PatientChartAnalysis
