// actions/patientActions.js
import axios from 'axios'
import {
  FETCH_PATIENT_REQUEST,
  FETCH_PATIENT_SUCCESS,
  FETCH_PATIENT_FAILURE,
  FETCH_PATIENT_ALL_REQUEST,
  FETCH_PATIENT_ALL_SUCCESS,
  FETCH_PATIENT_ALL_FAILURE,
} from '../constants/patientConstants'
import { API_URL_PATIENTS } from '../config/config'

export const fetchPatientRecords = (params) => async (dispatch) => {
  try {
    dispatch({ type: FETCH_PATIENT_REQUEST })

    const { data } = await axios.get(`${API_URL_PATIENTS}/patients-list`, {
      params,
    })

    dispatch({
      type: FETCH_PATIENT_SUCCESS,
      payload: data,
    })
  } catch (error) {
    dispatch({
      type: FETCH_PATIENT_FAILURE,
      payload: error.response?.data?.message || error.message,
    })
  }
}

export const fetchallPatientRecords = (params) => async (dispatch) => {
  try {
    dispatch({ type: FETCH_PATIENT_ALL_REQUEST })

    const { data } = await axios.get(`${API_URL_PATIENTS}/patients/all`, {
      params,
    })

    dispatch({
      type: FETCH_PATIENT_ALL_SUCCESS,
      payload: data,
    })
  } catch (error) {
    dispatch({
      type: FETCH_PATIENT_ALL_FAILURE,
      payload: error.response?.data?.message || error.message,
    })
  }
}
