import React from 'react'

// Helper function to format date
const formatDate = (dateString) => {
  const date = new Date(dateString)
  const year = date.getFullYear().toString().slice(-2) // Get last two digits of the year
  const month = (date.getMonth() + 1).toString().padStart(2, '0') // Months are 0-based, so add 1
  const day = date.getDate().toString().padStart(2, '0') // Pad single digits with a leading zero
  return `${year}-${month}-${day}`
}

const PatientTable = ({ records }) => {
  // Ensure records is an array
  if (!Array.isArray(records)) {
    return <p>No records available</p>
  }

  return (
    <table className="mt-4 min-w-full border border-gray-300 bg-white">
      <thead>
        <tr className="border-b bg-gray-100">
          <th className="px-4 py-2 text-left">Patient ID</th>
          {/* <th className="py-2 px-4 text-left">Patient Name</th> */}
          <th className="px-4 py-2 text-left">Gender</th>
          <th className="px-4 py-2 text-left">Age</th>
          <th className="px-4 py-2 text-left">Location</th>
          <th className="px-4 py-2 text-left">Date</th>
        </tr>
      </thead>
      <tbody>
        {records.map((record) => (
          <tr key={record._id} className="border-b">
            <td className="px-4 py-2">{record.patientId}</td>
            {/* <td className="py-2 px-4">{record.name}</td> */}
            <td className="px-4 py-2">{record.gender}</td>
            <td className="px-4 py-2">{record.age}</td>
            <td className="px-4 py-2">{record.location}</td>
            <td className="px-4 py-2">{formatDate(record.updatedAt)}</td>
          </tr>
        ))}
      </tbody>
    </table>
  )
}

export default PatientTable
