import {
  REGISTER_REQUEST,
  REGISTER_SUCCESS,
  REGISTER_FAILURE,
  LOGIN_REQUEST,
  LOGIN_SUCCESS,
  LOGIN_FAILURE,
  LOGOUT,
  GET_ALL_USERS_REQUEST,
  GET_ALL_USERS_SUCCESS,
  GET_ALL_USERS_FAILURE,
  REFRESH_TOKEN_REQUEST,
  REFRESH_TOKEN_SUCCESS,
  REFRESH_TOKEN_FAILURE,
  EDIT_USER_REQUEST,
  EDIT_USER_SUCCESS,
  EDIT_USER_FAILURE,
  DELETE_USER_REQUEST,
  DELETE_USER_SUCCESS,
  DELETE_USER_FAILURE,
  DELETE_ALL_USERS_REQUEST,
  DELETE_ALL_USERS_SUCCESS,
  DELETE_ALL_USERS_FAILURE,
} from '../constants/userConstants'

const initialState = {
  isAuthenticated: false,
  user: null,
  token: null,
  users: [],
  metadata: {},
  loading: false,
  errors: {},
}

export default function authReducer(state = initialState, action) {
  switch (action.type) {
    case REGISTER_REQUEST:
    case LOGIN_REQUEST:
    case GET_ALL_USERS_REQUEST:
    case REFRESH_TOKEN_REQUEST:
    case EDIT_USER_REQUEST:
    case DELETE_USER_REQUEST:
    case DELETE_ALL_USERS_REQUEST:
      return {
        ...state,
        loading: true,
      }
    case REGISTER_SUCCESS:
    case LOGIN_SUCCESS:
    case REFRESH_TOKEN_SUCCESS:
      return {
        ...state,
        isAuthenticated: true,
        user: action.payload.user,
        token: action.payload.token,
        loading: false,
      }
    case EDIT_USER_SUCCESS:
      return {
        ...state,
        users: state.users.map((user) =>
          user._id === action.payload._id ? action.payload : user
        ),
        loading: false,
      }
    case DELETE_USER_SUCCESS:
      return {
        ...state,
        users: state.users.filter((user) => user._id !== action.payload),
        loading: false,
      }
    case DELETE_ALL_USERS_SUCCESS:
      return {
        ...state,
        users: [],
        loading: false,
      }
    case REGISTER_FAILURE:
    case LOGIN_FAILURE:
    case GET_ALL_USERS_FAILURE:
    case REFRESH_TOKEN_FAILURE:
    case EDIT_USER_FAILURE:
    case DELETE_USER_FAILURE:
    case DELETE_ALL_USERS_FAILURE:
      return {
        ...state,
        errors: action.payload,
        loading: false,
      }
    case GET_ALL_USERS_SUCCESS:
      return {
        ...state,
        users: action.payload.users,
        metadata: action.payload.metadata,
        loading: false,
      }
    case LOGOUT:
      return {
        ...state,
        isAuthenticated: false,
        user: null,
        token: null,
        users: [],
        metadata: {},
      }
    default:
      return state
  }
}
