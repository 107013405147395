import React, { useEffect, useState, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faEllipsisV,
  faEdit,
  faTrash,
  faPlus,
  faSearch,
} from '@fortawesome/free-solid-svg-icons'
import WarningModal from './WarningModal'
import { deleteWellness, getAllWellness } from '../../actions/wellnessActions'
import WellnessPatientModal from './MembersModal'
import EditWellnessModal from './EditWellnessModal'

const formatDate = (dateString) => {
  const date = new Date(dateString)
  const year = date.getFullYear().toString().slice(-2)
  const month = String(date.getMonth() + 1).padStart(2, '0')
  const day = String(date.getDate()).padStart(2, '0')
  return `${year}-${month}-${day}`
}

const MembersList = () => {
  const dispatch = useDispatch()
  const { wellnessMembers, metadata, loading } = useSelector(
    (state) => state.wellness
  )

  const [currentPage, setCurrentPage] = useState(1)
  const pageSize = 10

  useEffect(() => {
    dispatch(getAllWellness({ page: currentPage, size: pageSize }))
  }, [dispatch, currentPage])

  const [menuOpen, setMenuOpen] = useState(null)
  const menuRef = useRef(null)
  const deleteButtonRef = useRef(null)
  const editButtonRef = useRef(null)
  const [modalOpen, setModalOpen] = useState(false)
  const [warningModalOpen, setWarningModalOpen] = useState(false)
  const [memberToDelete, setMemberToDelete] = useState(null)
  const [searchQuery, setSearchQuery] = useState('')
  const [editModalOpen, setEditModalOpen] = useState(false)
  const [memberToEdit, setMemberToEdit] = useState(null)

  const toggleMenu = (id) => {
    setMenuOpen(menuOpen === id ? null : id)
  }

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber)
  }

  const handleEdit = (member) => {
    setMemberToEdit(member)
    setEditModalOpen(true)
    setMenuOpen(null)
  }

  const handleDelete = (id) => {
    console.log(`Delete button clicked for member ID: ${id}`)
    setMemberToDelete(id)
    setWarningModalOpen(true)
  }

  const confirmDelete = () => {
    if (memberToDelete) {
      dispatch(deleteWellness(memberToDelete))
      setMemberToDelete(null)
      setWarningModalOpen(false)
    }
  }

  const cancelDelete = () => {
    setMemberToDelete(null)
    setWarningModalOpen(false)
  }

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        menuRef.current &&
        !menuRef.current.contains(event.target) &&
        deleteButtonRef.current &&
        !deleteButtonRef.current.contains(event.target) &&
        editButtonRef.current &&
        !editButtonRef.current.contains(event.target)
      ) {
        setMenuOpen(null)
        setEditModalOpen(false)
      }
    }

    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [])

  if (loading) return <p className="text-center text-lg">Loading...</p>

  return (
    <div className="container mx-auto p-4">
      <div className="mb-4 flex items-center justify-between">
        <button
          onClick={() => setModalOpen(true)}
          className="btn flex items-center rounded bg-brandPrimary px-4 py-2 text-white"
        >
          <FontAwesomeIcon icon={faPlus} className="mr-2" /> Add Member
        </button>
        <div className="flex items-center space-x-2">
          <input
            type="text"
            placeholder="Search..."
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
            className="rounded-lg border border-gray-300 px-4 py-2 focus:outline-none focus:ring-2 focus:ring-blue-500"
          />
          <button className="btn flex items-center rounded bg-gray-200 px-4 py-2 text-gray-600 hover:bg-gray-300">
            <FontAwesomeIcon icon={faSearch} className="mr-2" /> Search
          </button>
        </div>
      </div>
      <div className="mb-4 text-lg font-semibold text-gray-800">
        Patients: <span className="text-[#DB2627]">{metadata.total}</span>
      </div>
      <div className="overflow-x-auto">
        <table className="min-w-full rounded-lg border border-gray-200 bg-white shadow-md">
          <thead className="bg-gray-100 text-left text-gray-600">
            <tr>
              <th className="px-4 py-2">ID</th>
              <th className="px-4 py-2">First Name</th>
              <th className="px-4 py-2">Last Name</th>
              <th className="px-4 py-2">Phone Number</th>
              <th className="px-4 py-2">ID Number</th>
              <th className="px-4 py-2">Ins. Member Number</th>
              <th className="px-4 py-2">Email</th>
              <th className="px-4 py-2">Address</th>
              <th className="px-4 py-2">DOB</th>
              <th className="px-4 py-2">Date</th>
              <th className="px-4 py-2">Actions</th>
            </tr>
          </thead>
          <tbody>
            {wellnessMembers
              .filter(
                (member) =>
                  member.email &&
                  member.firstName
                    .toLowerCase()
                    .includes(searchQuery.toLowerCase())
              )
              .map((member) => (
                <tr
                  key={member._id}
                  className="border-b border-gray-200 hover:bg-gray-50"
                >
                  <td className="px-4 py-2">{member._id.substring(0, 6)}</td>
                  <td className="px-4 py-2">{member.firstName}</td>
                  <td className="px-4 py-2">{member.lastName}</td>
                  <td className="px-4 py-2">{member.phoneNumber}</td>
                  <td className="px-4 py-2">{member.idNumber}</td>
                  <td className="px-4 py-2">{member.insuranceMemberNumber}</td>
                  <td className="px-4 py-2">{member.email}</td>
                  <td className="px-4 py-2">{member.location}</td>
                  <td className="px-4 py-2">{formatDate(member.dateOfBirth)}</td>
                  <td className="px-4 py-2">{formatDate(member.createdAt)}</td>
                  <td className="px-4 py-2">
                    <div className="relative flex items-center" ref={menuRef}>
                      <button
                        onClick={() => toggleMenu(member._id)}
                        className="text-gray-600 hover:text-gray-900 focus:outline-none"
                      >
                        <FontAwesomeIcon icon={faEllipsisV} />
                      </button>
                      {menuOpen === member._id && (
                        <div className="absolute right-0 z-10 mt-1 flex items-center space-x-2">
                          <button
                            ref={editButtonRef} // Add ref here
                            onClick={() => handleEdit(member)}
                            className="block flex items-center py-2 text-gray-700 hover:bg-gray-100"
                          >
                            <FontAwesomeIcon icon={faEdit} className="ml-1" />
                          </button>
                          <button
                            ref={deleteButtonRef} // Add ref here
                            onClick={() => handleDelete(member._id)}
                            className="block flex items-center py-2 text-gray-700 hover:bg-gray-100"
                          >
                            <FontAwesomeIcon
                              icon={faTrash}
                              className="ml-1 text-red-600"
                            />
                          </button>
                        </div>
                      )}
                    </div>
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
      </div>
      <div className="mt-4 flex items-center justify-between">
        <button
          onClick={() => handlePageChange(metadata.current_page - 1)}
          disabled={!metadata.has_previous_page}
          className="btn rounded bg-gray-200 px-4 py-2 text-gray-600 hover:bg-gray-300"
        >
          Previous
        </button>
        <span className="text-gray-700">
          Page {metadata.current_page} of {metadata.last_page}
        </span>
        <button
          onClick={() => handlePageChange(metadata.current_page + 1)}
          disabled={!metadata.has_next_page}
          className="btn rounded bg-gray-200 px-4 py-2 text-gray-600 hover:bg-gray-300"
        >
          Next
        </button>
      </div>
      <WellnessPatientModal
        isOpen={modalOpen}
        onClose={() => setModalOpen(false)}
      />
      <EditWellnessModal
        isOpen={editModalOpen}
        onClose={() => setEditModalOpen(false)}
        member={memberToEdit}
      />
      <WarningModal
        isOpen={warningModalOpen}
        onClose={cancelDelete}
        firstName={
          wellnessMembers.find((member) => member._id === memberToDelete)
            ?.firstName
        }
        onConfirm={confirmDelete}
      />
    </div>
  )
}

export default MembersList
