import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faTachometerAlt,
  faChartLine,
  faBars,
  faTimes,
  faUsers,
} from '@fortawesome/free-solid-svg-icons'
import { useSelector } from 'react-redux' // Import useSelector

function Sidebar() {
  const [isOpen, setIsOpen] = useState(false)

  const toggleSidebar = () => {
    setIsOpen(!isOpen)
  }

  // Retrieve the user's role from the Redux store
  const { user } = useSelector((state) => state.auth)
  const userRole = user?.role || 'Staff' // Default to 'Staff' if role is not available

  return (
    <div className="relative">
      {/* Hamburger Menu Icon */}
      <div className="flex items-center p-4 lg:hidden">
        <button onClick={toggleSidebar} className="text-gray-900">
          <FontAwesomeIcon icon={isOpen ? faTimes : faBars} size="lg" />
        </button>
      </div>

      {/* Sidebar */}
      <div
        className={`fixed flex w-full transform flex-col bg-gray-100 text-gray-900 lg:static lg:block lg:w-64 ${isOpen ? 'translate-x-0' : '-translate-x-full'} transition-transform duration-300 ease-in-out lg:translate-x-0`}
      >
        <div className="flex items-center justify-center p-4">
          <img
            src="https://healthcamp-products.s3.us-east-2.amazonaws.com/Frame+6322.svg"
            alt="Logo"
            className="h-12 w-auto"
          />
        </div>
        <nav className="mt-10 flex-1">
          <ul>
            {/* Render Dashboard link only for Admin and Manager */}
            {(userRole === 'Admin' || userRole === 'Manager') && (
              <li className="mb-2">
                <Link
                  to="home"
                  className="mx-auto flex w-40 items-center justify-center rounded-md bg-brandPrimary p-2 text-white transition-colors duration-300 hover:bg-brandSecondary"
                >
                  <FontAwesomeIcon icon={faTachometerAlt} className="mr-2" />
                  Dashboard
                </Link>
              </li>
            )}

            {/* Render Analysis link for Admin, Manager, and Staff */}
            {(userRole === 'Admin' || userRole === 'Manager') && (
              <li className="mb-2">
                <Link
                  to="patient-analysis"
                  className="mx-auto flex w-40 items-center justify-center rounded-md bg-brandPrimary p-2 text-white transition-colors duration-300 hover:bg-brandSecondary"
                >
                  <FontAwesomeIcon icon={faChartLine} className="mr-2" />
                  Analysis
                </Link>
              </li>
            )}

            {/* Render Wellness link for Admin, Manager, and Staff */}
            {/* {(userRole === 'Manager' || userRole === 'Staff') && (
              <li className="mb-2">
                <Link
                  to="wellness"
                  className="mx-auto flex w-40 items-center justify-center rounded-md bg-brandPrimary p-2 text-white transition-colors duration-300 hover:bg-brandSecondary"
                >
                  <FontAwesomeIcon icon={faUsers} className="mr-2" />
                  Wellness
                </Link>
              </li>
            )} */}
          </ul>
        </nav>
      </div>
    </div>
  )
}

export default Sidebar
