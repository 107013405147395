import React from 'react'
import PatientChartAnalysis from '../Patients/PatientChartAnalysis'

function PatientChartPage() {
  return (
    <div>
      <PatientChartAnalysis />
    </div>
  )
}

export default PatientChartPage
