import React from 'react'
import PatientList from '../Patients/PatientList'

function PatientsPage() {
  return (
    <div>
      <PatientList />
    </div>
  )
}

export default PatientsPage
