import React from 'react'
import { Routes, Route } from 'react-router-dom'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import AdminLogin from './components/Admin/Login'
import AdminRegistration from './components/Admin/Register'
import Dashboard from './components/Dashboard/Dashboard'
// import AdminPage from './components/Dashboard/AdminPage';
import Home from './components/Dashboard/Home'
import PatientPage from './components/Dashboard/PatientsPage'
import PatientChartPage from './components/Dashboard/PatientChartPage'
import Wellness from './components/Dashboard/Wellness'
import WellnessRegistrationForm from './components/Wellness/WellnessRegistrationForm'

function App() {
  return (
    <div>
      <ToastContainer position="top-center" />
      <Routes>
        <Route path="/" element={<AdminLogin />} />
        <Route path="/zuri_health/register" element={<AdminRegistration />} />
        <Route
          path="/wellness/register"
          element={<WellnessRegistrationForm />}
        />
        <Route path="/dashboard" element={<Dashboard />}>
          <Route path="home" element={<Home />} />
          {/* <Route path="admin" element={<AdminPage />} /> */}
          <Route path="patient-list" element={<PatientPage />} />
          <Route path="patient-analysis" element={<PatientChartPage />} />
          <Route path="wellness" element={<Wellness />} />
        </Route>
      </Routes>
    </div>
  )
}

export default App
