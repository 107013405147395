import { createStore, combineReducers, applyMiddleware } from 'redux'
import { thunk } from 'redux-thunk'
import { composeWithDevTools } from '@redux-devtools/extension'
import authReducer from './reducers/userReducers'
import { persistStore, persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import { encryptTransform } from 'redux-persist-transform-encrypt'
import { SECRET_KEY } from './config/config'
import patientReducer from './reducers/patientReducer'
import chartReducer from './reducers/chartReducer'
import wellnessReducer from './reducers/wellnessReducer'

const reducer = combineReducers({
  auth: authReducer,
  patient: patientReducer,
  chart: chartReducer,
  wellness: wellnessReducer,
})

const middleware = [thunk]

const persistConfig = {
  key: 'root',
  storage,
  transforms: [
    encryptTransform({
      secretKey: SECRET_KEY,
      onError: function (error) {
        // Handle the error.
        console.error('Encryption error:', error)
      },
    }),
  ],
}

const persistedReducer = persistReducer(persistConfig, reducer)

const store = createStore(
  persistedReducer,
  composeWithDevTools(applyMiddleware(...middleware))
)

const persistor = persistStore(store)

export { store, persistor }
