import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { logout } from '../../actions/userActions'

function Navbar() {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const auth = useSelector((state) => state.auth)
  const { user } = auth

  const handleLogout = () => {
    dispatch(logout())
    navigate('/')
  }

  return (
    <div className="flex items-center justify-between bg-brandSecondary p-4 text-white">
      <div className="text-lg font-bold">
        {user ? `Welcome, ${user.firstName} ${user.lastName}` : 'Navbar'}
      </div>
      <button
        onClick={handleLogout}
        className="flex items-center justify-center rounded-md bg-red-600 p-2 transition-colors duration-300 hover:bg-red-700"
      >
        Logout
      </button>
    </div>
  )
}

export default Navbar
