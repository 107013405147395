import React from 'react'
import { Outlet } from 'react-router-dom'
import Sidebar from './Sidebar'
import Navbar from './Navbar'

function Dashboard() {
  return (
    <div className="flex h-screen">
      <Sidebar />
      <div className="flex flex-1 flex-col">
        <Navbar />
        <div className="flex-1 bg-gray-100 p-4">
          <div className="rounded bg-white p-6 shadow-md">
            <Outlet />
          </div>
        </div>
      </div>
    </div>
  )
}

export default Dashboard
