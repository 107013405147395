import axios from 'axios'
import {
  ADD_WELLNESS_REQUEST,
  ADD_WELLNESS_SUCCESS,
  ADD_WELLNESS_FAILURE,
  UPDATE_WELLNESS_REQUEST,
  UPDATE_WELLNESS_SUCCESS,
  UPDATE_WELLNESS_FAILURE,
  DELETE_WELLNESS_REQUEST,
  DELETE_WELLNESS_SUCCESS,
  DELETE_WELLNESS_FAILURE,
  GET_ALL_WELLNESS_REQUEST,
  GET_ALL_WELLNESS_SUCCESS,
  GET_ALL_WELLNESS_FAILURE,
} from '../constants/wellnessConstants'
import { API_URL_MAIN, API_KEY } from '../config/config'
import { toast } from 'react-toastify'

const axiosConfig = {
  headers: {
    'X-API-KEY': API_KEY,
  },
}

// Add Wellness Member
export const addWellness = (wellnessData) => async (dispatch) => {
  dispatch({ type: ADD_WELLNESS_REQUEST })
  try {
    const res = await axios.post(`${API_URL_MAIN}/wellness/new`, wellnessData)
    dispatch({
      type: ADD_WELLNESS_SUCCESS,
      payload: res.data,
    })
    toast.success('Wellness details added successfully')
  } catch (error) {
    dispatch({
      type: ADD_WELLNESS_FAILURE,
      payload: error.response.data,
    })
    toast.error(
      'Failed to register. Phone number, email, or ID number already exists.'
    )
  }
}

// Update Wellness Member
export const updateWellness =
  (wellnessId, wellnessData) => async (dispatch) => {
    dispatch({ type: UPDATE_WELLNESS_REQUEST })
    try {
      const res = await axios.put(
        `${API_URL_MAIN}/wellness/member/${wellnessId}`,
        wellnessData,
        axiosConfig
      )
      dispatch({
        type: UPDATE_WELLNESS_SUCCESS,
        payload: res.data,
      })
      toast.success('Wellness member updated successfully')
    } catch (error) {
      dispatch({
        type: UPDATE_WELLNESS_FAILURE,
        payload: error.response.data,
      })
      toast.error('Failed to update wellness member')
    }
  }

// Delete Wellness Member
export const deleteWellness = (wellnessId) => async (dispatch) => {
  dispatch({ type: DELETE_WELLNESS_REQUEST })
  try {
    await axios.delete(
      `${API_URL_MAIN}/wellness/member/${wellnessId}`,
      axiosConfig
    )
    dispatch({
      type: DELETE_WELLNESS_SUCCESS,
      payload: wellnessId,
    })
    toast.success('Wellness member deleted successfully')
  } catch (error) {
    dispatch({
      type: DELETE_WELLNESS_FAILURE,
      payload: error.response.data,
    })
    toast.error('Failed to delete wellness member')
  }
}

// Get All Wellness Members
export const getAllWellness =
  ({ page = 1, size = 10 } = {}) =>
  async (dispatch) => {
    dispatch({ type: GET_ALL_WELLNESS_REQUEST })
    try {
      const { data } = await axios.get(`${API_URL_MAIN}/wellness/all`, {
        params: { page, size },
        ...axiosConfig,
      })
      dispatch({
        type: GET_ALL_WELLNESS_SUCCESS,
        payload: data,
      })
    } catch (error) {
      dispatch({
        type: GET_ALL_WELLNESS_FAILURE,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      })
    }
  }
