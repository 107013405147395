import React, { useState, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimes } from '@fortawesome/free-solid-svg-icons'
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { updateWellness } from '../../actions/wellnessActions' // Update this action import

const EditWellnessModal = ({ isOpen, onClose, wellnessData }) => {
  const dispatch = useDispatch()

  const [firstName, setFirstName] = useState('')
  const [lastName, setLastName] = useState('')
  const [phoneNumber, setPhoneNumber] = useState('')
  const [idNumber, setIdNumber] = useState('')
  const [insuranceMemberNumber, setInsuranceMemberNumber] = useState('')
  const [email, setEmail] = useState('')
  const [location, setLocation] = useState('')

  useEffect(() => {
    if (wellnessData) {
      setFirstName(wellnessData.firstName || '')
      setLastName(wellnessData.lastName || '')
      setPhoneNumber(wellnessData.phoneNumber || '')
      setIdNumber(wellnessData.idNumber || '')
      setInsuranceMemberNumber(wellnessData.insuranceMemberNumber || '')
      setEmail(wellnessData.email || '')
      setLocation(wellnessData.location || '')
    }
  }, [wellnessData])

  const handleSubmit = async (e) => {
    e.preventDefault()
    const updatedWellness = {
      firstName,
      lastName,
      phoneNumber,
      idNumber,
      insuranceMemberNumber,
      email,
      location,
    }
    try {
      await dispatch(updateWellness(wellnessData._id, updatedWellness))
      toast.success('Wellness data updated successfully')
      onClose()
    } catch (error) {
      toast.error('Failed to update wellness data')
    }
  }

  if (!isOpen) return null

  return (
    <>
      <div className="fixed inset-0 z-50 flex items-center justify-center bg-gray-800 bg-opacity-50">
        <div className="relative w-full max-w-md rounded-lg bg-white p-6 shadow-lg">
          <button
            onClick={onClose}
            className="absolute right-4 top-4 text-gray-500 hover:text-gray-700 focus:outline-none"
          >
            <FontAwesomeIcon icon={faTimes} size="lg" />
          </button>
          <form onSubmit={handleSubmit} className="space-y-4">
            <div>
              <label
                htmlFor="firstName"
                className="block text-sm font-medium text-gray-700"
              >
                First Name:
              </label>
              <input
                id="firstName"
                type="text"
                value={firstName}
                onChange={(e) => setFirstName(e.target.value)}
                required
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
              />
            </div>
            <div>
              <label
                htmlFor="lastName"
                className="block text-sm font-medium text-gray-700"
              >
                Last Name:
              </label>
              <input
                id="lastName"
                type="text"
                value={lastName}
                onChange={(e) => setLastName(e.target.value)}
                required
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
              />
            </div>
            <div>
              <label
                htmlFor="phoneNumber"
                className="block text-sm font-medium text-gray-700"
              >
                Phone Number:
              </label>
              <input
                id="phoneNumber"
                type="text"
                value={phoneNumber}
                onChange={(e) => setPhoneNumber(e.target.value)}
                required
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
              />
            </div>
            <div>
              <label
                htmlFor="idNumber"
                className="block text-sm font-medium text-gray-700"
              >
                ID Number:
              </label>
              <input
                id="idNumber"
                type="text"
                value={idNumber}
                onChange={(e) => setIdNumber(e.target.value)}
                required
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
              />
            </div>
            <div>
              <label
                htmlFor="insuranceMemberNumber"
                className="block text-sm font-medium text-gray-700"
              >
                Insurance Member Number:
              </label>
              <input
                id="insuranceMemberNumber"
                type="text"
                value={insuranceMemberNumber}
                onChange={(e) => setInsuranceMemberNumber(e.target.value)}
                required
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
              />
            </div>
            <div>
              <label
                htmlFor="email"
                className="block text-sm font-medium text-gray-700"
              >
                Email:
              </label>
              <input
                id="email"
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
              />
            </div>
            <div>
              <label
                htmlFor="location"
                className="block text-sm font-medium text-gray-700"
              >
                Location:
              </label>
              <input
                id="location"
                type="text"
                value={location}
                onChange={(e) => setLocation(e.target.value)}
                required
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
              />
            </div>
            <div className="flex justify-end">
              <button
                type="button"
                onClick={onClose}
                className="mr-2 rounded-md bg-[#DB2627] px-4 py-2 text-white shadow-sm"
              >
                Cancel
              </button>
              <button
                type="submit"
                className="rounded-md bg-[#26CED1] px-4 py-2 text-white shadow-sm"
              >
                Save
              </button>
            </div>
          </form>
        </div>
      </div>
      <ToastContainer />
    </>
  )
}

export default EditWellnessModal
