export const ADD_WELLNESS_REQUEST = 'ADD_WELLNESS_REQUEST'
export const ADD_WELLNESS_SUCCESS = 'ADD_WELLNESS_SUCCESS'
export const ADD_WELLNESS_FAILURE = 'ADD_WELLNESS_FAILURE'

export const UPDATE_WELLNESS_REQUEST = 'UPDATE_WELLNESS_REQUEST'
export const UPDATE_WELLNESS_SUCCESS = 'UPDATE_WELLNESS_SUCCESS'
export const UPDATE_WELLNESS_FAILURE = 'UPDATE_WELLNESS_FAILURE'

export const DELETE_WELLNESS_REQUEST = 'DELETE_WELLNESS_REQUEST'
export const DELETE_WELLNESS_SUCCESS = 'DELETE_WELLNESS_SUCCESS'
export const DELETE_WELLNESS_FAILURE = 'DELETE_WELLNESS_FAILURE'

export const GET_ALL_WELLNESS_REQUEST = 'GET_ALL_WELLNESS_REQUEST'
export const GET_ALL_WELLNESS_SUCCESS = 'GET_ALL_WELLNESS_SUCCESS'
export const GET_ALL_WELLNESS_FAILURE = 'GET_ALL_WELLNESS_FAILURE'
