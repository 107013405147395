import {
  ADD_WELLNESS_REQUEST,
  ADD_WELLNESS_SUCCESS,
  ADD_WELLNESS_FAILURE,
  UPDATE_WELLNESS_REQUEST,
  UPDATE_WELLNESS_SUCCESS,
  UPDATE_WELLNESS_FAILURE,
  DELETE_WELLNESS_REQUEST,
  DELETE_WELLNESS_SUCCESS,
  DELETE_WELLNESS_FAILURE,
  GET_ALL_WELLNESS_REQUEST,
  GET_ALL_WELLNESS_SUCCESS,
  GET_ALL_WELLNESS_FAILURE,
} from '../constants/wellnessConstants'
import { toast } from 'react-toastify'

const initialState = {
  wellnessMembers: [],
  metadata: {},
  loading: false,
  errors: {},
}

export default function wellnessReducer(state = initialState, action) {
  switch (action.type) {
    case ADD_WELLNESS_REQUEST:
    case UPDATE_WELLNESS_REQUEST:
    case DELETE_WELLNESS_REQUEST:
    case GET_ALL_WELLNESS_REQUEST:
      return {
        ...state,
        loading: true,
      }
    case ADD_WELLNESS_SUCCESS:
      // toast.success('Wellness member added successfully');
      return {
        ...state,
        wellnessMembers: [...state.wellnessMembers, action.payload.data],
        loading: false,
      }
    case UPDATE_WELLNESS_SUCCESS:
      // toast.success('Wellness member updated successfully');
      return {
        ...state,
        wellnessMembers: state.wellnessMembers.map((member) =>
          member._id === action.payload.data._id ? action.payload.data : member
        ),
        loading: false,
      }
    case DELETE_WELLNESS_SUCCESS:
      // toast.success('Wellness member deleted successfully');
      return {
        ...state,
        wellnessMembers: state.wellnessMembers.filter(
          (member) => member._id !== action.payload
        ),
        loading: false,
      }
    case GET_ALL_WELLNESS_SUCCESS:
      return {
        ...state,
        wellnessMembers: action.payload.data,
        metadata: action.payload.metadata,
        loading: false,
      }
    case ADD_WELLNESS_FAILURE:
      // toast.error(`Failed to add wellness member: ${action.payload.message}`);
      return {
        ...state,
        errors: action.payload,
        loading: false,
      }
    case UPDATE_WELLNESS_FAILURE:
      // toast.error(`Failed to update wellness member: ${action.payload.message}`);
      return {
        ...state,
        errors: action.payload,
        loading: false,
      }
    case DELETE_WELLNESS_FAILURE:
      // toast.error(`Failed to delete wellness member: ${action.payload.message}`);
      return {
        ...state,
        errors: action.payload,
        loading: false,
      }
    case GET_ALL_WELLNESS_FAILURE:
      // toast.error(`Failed to fetch wellness members: ${action.payload.message}`);
      return {
        ...state,
        errors: action.payload,
        loading: false,
      }
    default:
      return state
  }
}
