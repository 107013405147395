import React from 'react'
import HomeLogo from '../Home/HomeLogo'

function Home() {
  return (
    <div>
      <HomeLogo />
    </div>
  )
}

export default Home
